.modal-container {
  position: fixed; /* Ensure the modal covers the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px; /* Slightly rounded corners for a modern look */
  width: 90%; /* Almost full width on mobile */
  max-width: 400px; /* Max width for larger screens */
  text-align: center;
  z-index: 1001;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.modal-content input {
  width: 100%;
  padding: 12px; /* Increased padding for easier touch input */
  margin-bottom: 15px; /* More space below the input field */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; /* Larger font for readability */
}

.error-message {
  color: red;
  margin-bottom: 15px;
  font-size: 14px; /* Ensure error messages are easy to read */
}

.button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on mobile */
  gap: 10px; /* Space between buttons */
  padding-top: 10px; /* Space above buttons */
}

.button-container button {
  background-color: black;
  color: white;
  border: none;
  padding: 12px; /* Increased padding for easier touch input */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 16px; /* Larger font for readability */
}

.button-container button:hover {
  background-color: #FFD800; /* Darker shade on hover */
}

.modal-content:before {
  content: 'Enter TripSlip Code';
  display: block;
  font-size: 20px;
  margin-bottom: 20px; /* Space between the title and the input field */
}
