.login-container {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.full-height {
    min-height: 100vh;
}

.image-side {
    background-image: url('../../assets/images/tripslipapplandingpic.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.mobile-overlay {
    background-color: rgba(255, 204, 0, 0.8);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-content {
    padding: 2rem;
}

.form-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.logo-container {
    text-align: center;
    margin-bottom: 2rem;
}

.logo {
    max-width: 80%;
    height: auto;
}

.tagline {
    font-size: 1rem;
    color: #666;
    margin-top: 0.5rem;
}

.login-form {
    width: 100%;
}

.submit-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.links {
    text-align: center;
    margin-top: 1rem;
}

.permission-slip-button {
    margin-top: 2rem;
}

@media (max-width: 600px) {
    .image-side {
        min-height: auto;
    }
    
    .mobile-overlay {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
    .logo {
        max-width: 60%;
    }
    
    .tagline {
        font-size: 0.9rem;
    }
}