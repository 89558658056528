.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    box-sizing: border-box;
    outline: none;
  }
  
  .signature-img {
    max-width: 100px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .emergency-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
  