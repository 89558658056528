.dashboard-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  flex-direction: row-reverse;
}

.dashboard-section {
  flex: 1;
  min-width: 300px;
  max-width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.Link {
  background-color: black;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.Link:hover {
  background-color: #333;
}

.info-box {
  background-color: #f2f2f2;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.info-box button {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .dashboard-container {
    flex-direction: column;
  }

  .dashboard-section {
    min-width: 100%;
  }

  .info-box {
    padding: 15px;
  }

  .info-box button {
    width: 100%;
  }
}