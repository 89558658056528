.response-modal-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.response-modal-backdrop {
  position: fixed; /* Changed to fixed to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px; /* Added padding for small screen spacing */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.response-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width on mobile */
  max-width: 400px; /* Adjusted max-width for mobile-friendly size */
  z-index: 1001;
  box-sizing: border-box; /* Ensure padding is included in the width */
  text-align: center; /* Center text alignment */
}

.response-modal-content h2 {
  margin-top: 0;
  font-size: 24px; /* Adjust font size for readability */
}

.response-modal-content p {
  margin: 15px 0; /* Adjust margin for spacing */
  font-size: 18px; /* Adjust font size for readability */
}

.response-modal-content button {
  margin-top: 20px;
  display: block;
  width: 100%; /* Full width for mobile */
  padding: 12px; /* Increased padding for easier tap interaction */
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-size: 16px; /* Adjust font size for readability */
  cursor: pointer;
}

.response-modal-content button:hover {
  background-color: #FFD800;
  color: black;
}
