.dashboard-container {
  padding: 16px;
}

.tableHead .MuiTableCell-root {
  color: #FFCC00;
  background-color: #000000;
  font-weight: bold;
}

.no-trips-or-bookings-box {
  background-color: white;
  color: black;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
}

.no-trips-or-bookings-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.no-trips-or-bookings-text,
.no-trips-or-bookings-description {
  margin-bottom: 16px;
}

.no-trips-or-bookings-button {
  background-color: #FFCC00;
  color: black;
  font-weight: bold;
  margin-top: 16px;
}

.no-trips-or-bookings-button:hover {
  background-color: #ffdb4d;
}

.showMoreButton {
  margin-top: 16px;
}

@media (max-width: 600px) {
  .dashboard-container {
    padding: 8px;
  }

  .no-trips-or-bookings-box {
    padding: 16px;
  }

  .no-trips-or-bookings-title {
    font-size: 1.25rem;
  }

  .no-trips-or-bookings-text,
  .no-trips-or-bookings-description {
    font-size: 0.875rem;
  }
}