.sigCanvas {
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .sigCanvas {
    width: 100% !important;
    height: auto !important;
  }
}

.terms-link {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}

.terms-link:hover {
  text-decoration: none;
}

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clear-button {
  margin-top: 10px;
}

@media (max-width: 600px) {
  .location-info {
    flex-direction: column;
  }

  .location-info > div {
    width: 100%;
    margin-bottom: 20px;
  }
}