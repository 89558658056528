/* Global Styles */
body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Form container styles */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  width: 100%;
}

.form-container label {
  display: block;
  margin: 10px 0;
}

input, textarea {
  width: 100%; /* Full width for mobile screens */
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  background-color: black;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
  width: 100%; /* Full width for buttons */
  padding: 10px;
  border: 2px solid #fcd22d;
  border-radius: 5px;
  text-align: center;
}

.button:hover {
  background-color: black;
  color: white;
}

/* Grades container styles */
.grades-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for better mobile view */
  max-width: 100%; 
  margin: 10px 0;
}

.grade-checkbox {
  flex-basis: calc(50% - 10px); /* Two columns on mobile */
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.grade-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

/* Cost container styles */
.cost-container {
  display: flex;
  flex-direction: column; /* Stack items on mobile */
  width: 100%;
  margin: 10px 0;
}

.cost-label {
  margin-bottom: 5px; /* Adjust space between label and checkbox */
}

/* Modal container styles */
.modal-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%; /* Adjust width for mobile */
  max-width: 400px;
  z-index: 1001;
  text-align: center;
}

.modal button {
  margin: 10px;
  padding: 10px 20px;
}

.modal p {
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .form-container {
    padding: 10px;
  }

  .button {
    font-size: 14px;
    padding: 8px;
  }

  .grade-checkbox {
    flex-basis: 100%; /* Single column on very small screens */
  }

  .cost-container {
    flex-direction: column;
  }

  .modal {
    padding: 15px;
    width: 80%;
  }

  .modal button {
    width: 100%; /* Full-width buttons on mobile */
  }
}
