* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
}